import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import Form from "react-bootstrap/Form";
import TelegramIcon from "../assets/svg/TelegramIcon";
import WhatsappIcon from "../assets/svg/WhatsappIcon";
import EyeIcon from "../assets/svg/EyeIcon";
import { useNavigate } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import GamesIcon from "../assets/svg/GamesIcon";
import HomeIcon from "../assets/svg/HomeIcon";
import SearchIcon from "../assets/images/search-icon.svg";
import SearchClearIcon from "../assets/images/search-clear-icon.svg";
import LoginIcon from "../assets/images/login-icon.svg";
import LoginIcon2 from "../assets/images/login-icon2.svg";
import UserIcon from "../assets/images/user-icon.svg";
import LiveIcon from "../assets/images/live-icon.svg";
// import RiExchangeFill from "../assets/svg/ProfileIcon";
import Exchange from "../assets/images/Exchange.png";
import SportsIcon from "../assets/svg/SportsIcon";
import CashierIcon from "../assets/svg/CashierIcon";
import logo from "../assets/svg/logo_new.png";
import { useSelector } from "react-redux";
const Header = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);

  return (
    <header className="header beforeheader">
      <div className="top-header">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <div className="logo">
                    <a href="/">
                      <img src={appDetails?.LOGO_URL} alt="Logo" />
                    </a>
                  </div>
                  <div className="searchbar d-none d-xl-block">
                    <img src={SearchIcon} alt="search" />
                    <Form.Control type="text" placeholder="Search Events" />
                    <Button
                      type="submit"
                      className="search-clear"
                      id="searchClear"
                      style={{ display: "none" }}
                    >
                      <img src={SearchClearIcon} alt="search clear" />
                    </Button>
                  </div>
                </div>
                <div className="headerRight">
                  <Form>
                    <div className="d-none d-md-flex align-items-center">
                      <Form.Group
                        className="form-group user"
                        controlId="loginUserName"
                      >
                        <img src={UserIcon} alt="user" />
                        <Form.Control type="text" placeholder="Username" />
                      </Form.Group>
                      <Form.Group
                        className="form-group"
                        controlId="loginPassword"
                      >
                        <Form.Control type="password" placeholder="Password" />
                      </Form.Group>
                      <Form.Group
                        className="form-group"
                        controlId="loginValidation"
                      >
                        <Form.Control
                          type="password"
                          placeholder="Validation"
                        />
                        <div className="validation">8705</div>
                      </Form.Group>
                    </div>
                    <div className="btns d-flex align-items-center">
                      <Form.Group className="form-group">
                        <Button
                          variant="primary"
                          onClick={() => {
                            navigate("/sign-in");
                          }}
                          className="button-primary ms-1 login-bnt"
                        >
                          <img
                            src={LoginIcon2}
                            alt="login"
                            className="d-md-none"
                          />
                          Login
                          <img
                            src={LoginIcon}
                            alt="login"
                            className="d_sm_none"
                          />
                        </Button>
                      </Form.Group>
                      <Form.Group className="form-group">
                        <Button
                          variant="primary"
                          onClick={() => {
                            navigate("/mobile-number");
                          }}
                          className="button-primary signup"
                        >
                          Sign up
                        </Button>
                      </Form.Group>
                    </div>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="bot-header d-none d-xxl-block">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <div className="middle-part">
                <Navbar expand="lg">
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                      <Nav.Link className="active" href="/home">
                        Home
                      </Nav.Link>
                      <Nav.Link href="javascript:void(0)">In-Play</Nav.Link>
                      <Nav.Link href="javascript:void(0)">
                        Multi Markets
                      </Nav.Link>
                      <Nav.Link href="/sportsbook">
                        Cricket
                        <div className="live-match">
                          <img src={LiveIcon} alt="live-icon" />
                          <span>2</span>
                        </div>
                      </Nav.Link>
                      <Nav.Link href="javascript:void(0)">
                        Soccer
                        <div className="live-match">
                          <img src={LiveIcon} alt="live-icon" />
                          <span>25</span>
                        </div>
                      </Nav.Link>
                      <Nav.Link href="javascript:void(0)">
                        Tennis
                        <div className="live-match">
                          <img src={LiveIcon} alt="live-icon" />
                          <span>9</span>
                        </div>
                      </Nav.Link>
                      <Nav.Link href="javascript:void(0)">
                        Virtual Cricket
                      </Nav.Link>
                      <Nav.Link href="javascript:void(0)">
                        E-Soccer
                        <div className="live-match">
                          <img src={LiveIcon} alt="live-icon" />
                          <span>2</span>
                        </div>
                      </Nav.Link>
                      <Nav.Link href="javascript:void(0)">IPL Winner</Nav.Link>
                      <Nav.Link href="javascript:void(0)">Binary</Nav.Link>
                      <Nav.Link href="javascript:void(0)">Sky Trader</Nav.Link>
                    </Nav>
                  </Navbar.Collapse>
                </Navbar>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="news">
        <div className="news-text">News</div>
        <marquee>
          <a>
            <span>02 May 2023</span>Event :- Gujarat Titans v Delhi Capitals ..
            Market .. 10 Over DC ' 52 - 54 ' (IST 20:01:50 - 20:01:55) Bets
            Voided Because Wrong Rate Offered by Mistake .. Sorry for the
            Inconvenience Caused
          </a>
          <a>
            <span>02 May 2023</span>Event :- Gujarat Titans v Delhi Capitals ..
            Market .. 10 Over DC ' 52 - 54 ' (IST 20:01:50 - 20:01:55) Bets
            Voided Because Wrong Rate Offered by Mistake .. Sorry for the
            Inconvenience Caused
          </a>
        </marquee>
      </div>

      {/* <Offcanvas
        className={"steps-canvas"}
        show={show}
        onHide={handleClose}
        placement={"end"}
      >
        <Offcanvas.Header closeButton="closeButton" closeVariant="white">
          <Offcanvas.Title>
            <span className="top-heading">Sign in now!</span>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="form-steps">
            <h2 className="h2">Hello Again!</h2>
            <p>
              Welcome back, you've
              <br />
              been missed!
            </p>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicName">
                <Form.Control type="text" placeholder="Enter Username*" />
              </Form.Group>
              <Form.Group
                className="mb-3 position-relative"
                controlId="formBasicPassword"
              >
                <Form.Control type="password" placeholder="Password*" />
                <span className="icon">
                  <EyeIcon />
                </span>
              </Form.Group>
              <div className="d-flex justify-content-end mb-3">
                <a className="form-link" href="#">
                  Password Recovery
                </a>
              </div>
              <Button variant="primary" type="submit">
                Sign in
              </Button>
            </Form>
            <div className="sign-up mt-4">
              Not a member?
              <br />
              <a href="register">Sign Up Now</a>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas> */}
    </header>
  );
};

export default Header;
