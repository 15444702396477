import React, { useEffect, useState } from "react";
import { Container, Row, Col, Nav, Navbar, Button } from "react-bootstrap";
import { Dropdown, DropdownButton, ButtonGroup } from "react-bootstrap";
import { Offcanvas, Form, Modal } from "react-bootstrap";
import MenuIcon from "../assets/svg/MenuIcon";
import ProfileIcon from "../assets/svg/ProfileIcon";
import TelegramIcon from "../assets/svg/TelegramIcon";
import WalletIcon from "../assets/svg/WalletIcon";
import WhatsappIcon from "../assets/svg/WhatsappIcon";
import BetIcon from "../assets/svg/BetIcon";
import SettingIcon from "../assets/svg/SettingIcon";
import StatementIcon from "../assets/svg/StatementIcon";
// import LogoutIcon from "../assets/svg/LogoutIcon";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../redux/auth/actions";
import logo from "../assets/svg/logo_new.png";
import CashierIcon from "../assets/svg/CashierIcon";
import GamesIcon from "../assets/svg/GamesIcon";
import HomeIcon from "../assets/svg/HomeIcon";
// import RiExchangeFill from "../assets/svg/ProfileIcon";
import Exchange from "../assets/images/Exchange.png";
import SearchIcon from "../assets/images/search-icon.svg";
import SearchClearIcon from "../assets/images/search-clear-icon.svg";
import UserIcon from "../assets/images/user-icon.svg";
import CasinoIcon from "../assets/images/casino-icon.png";
import NewImg from "../assets/images/new-img.png";
import LiveIcon from "../assets/images/live-icon.svg";
import RefreshIcon from "../assets/images/refresh-icon.svg";
import LogoutIcon from "../assets/images/logout-icon.svg";
import SttingIcon from "../assets/images/stting-icon.svg";
import MybetIcon from "../assets/images/mybet-icon.svg";
import SportsIcon from "../assets/svg/SportsIcon";
import numberWithCommas from "../utils/numbersWithComma";
import { IoWalletOutline } from "react-icons/io5";
import ExchangeFundsFormPopUp from "../views/components/ExchangeFundsFormPopUp";
import { CasinoProviders } from "../lib/data";
// import Index from "../views/pages/privatePages/exchangepopup";
import { getExposureAmount } from "../redux/auth/actions";
import { getBetMatches, getUserBet } from "../redux/sports/actions";
const HeaderAfterLogin = () => {
  const { user, wallet, exposureAmount } = useSelector((state) => state.auth);
  const { betMatches, userBets } = useSelector((state) => state.sports);
  // const betMatches = [
  //   {
  //     exposureAmount: 6438.5,
  //     matchName: "Australia v West Indies",
  //     refMatchId: 10,
  //     totalBets: 2,
  //   },
  // ];
  // const userBets = [
  //   {
  //     odds: 130,
  //     stack: 50,
  //     selectionType: "back",
  //     status: "open",
  //     createdAt: "2022-12-02T03:07:13.000Z",
  //   },
  //   {
  //     odds: 1.23,
  //     stack: 50,
  //     selectionType: "lay",
  //     status: "open",
  //     createdAt: "2022-12-02T03:07:04.000Z",
  //   },
  // ];
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();
  const { appDetails } = useSelector((state) => state.app);
  const [showexchangeModal, setShowexchangeModal] = useState(false);
  const [showBetModal, setShowBetModal] = useState(false);
  const [showUserBetModal, setShowUserBetModal] = useState(false);

  const handleCloseModal = () => setShowexchangeModal(false);
  const handleShowModal = () => setShowexchangeModal(true);
  // function handelSubmit() {
  //   return <Index />;
  // }
  const [showToggle, setShowToggle] = useState(true);

  useEffect(() => {
    if (showToggle) {
      document.body.classList.add("header_open");
    } else {
      document.body.classList.remove("header_open");
    }
  }, [showToggle]);

  useEffect(() => {
    dispatch(getExposureAmount());
    dispatch(getBetMatches());
    setShowToggle(window.screen.width > 992);
    return () => {};
  }, []);

  const handleRowClick = (item) => {
    dispatch(
      getUserBet({
        skip: 0,
        pageSize: 10,
        refmatchid: item?.refMatchId,
      })
    );
    setShowBetModal(false);
    setShowUserBetModal(true);
  };
  return (
    <header className="header aftrlgn">
      <div className="top-header">
        <Container fluid>
          <Row>
            <Col xs={12}>
              <div className="d-flex justify-content-between align-items-start">
                <div className="d-flex">
                  <div className="logo">
                    <a href="/home">
                      <img src={appDetails?.LOGO_URL || logo} />
                    </a>
                  </div>
                  <div className="searchbar d-none d-xl-block">
                    <img src={SearchIcon} alt="search" />
                    <Form.Control type="text" placeholder="Search Events" />
                    <Button
                      type="submit"
                      className="search-clear"
                      id="searchClear"
                      style={{ display: "none" }}
                    >
                      <img src={SearchClearIcon} alt="search clear" />
                    </Button>
                  </div>
                </div>
                <div className="headerRight">
                  <div className="my-bets">
                    <a href="" className="">
                      <img src={MybetIcon} alt="mybet icon" />
                      Bets
                    </a>
                  </div>
                  <div className="my-info">
                    <div className="funds">
                      <ul>
                        {/* <li>
                          <a href="#">
                            <span>Exchange Bal:</span>{" "}
                            <span>
                              IR {numberWithCommas(wallet?.sports || 0)}.00
                            </span>
                          </a>
                        </li> */}
                        <li className="balance">
                          <a href="#" className="main-bal">
                            <span>
                              Main <span className="bal-text">Balance</span>
                            </span>{" "}
                            <span>
                              IR {numberWithCommas(wallet?.cash || 0)}.00
                            </span>
                          </a>
                          <a
                            href="javascript:void(0)"
                            onClick={() => setShowBetModal(true)}
                          >
                            <span>Exposure</span>{" "}
                            <span>
                              {numberWithCommas(exposureAmount || 0)}.00
                            </span>
                          </a>
                        </li>
                        <li className="bal-count">
                          +<span>5</span>
                        </li>
                      </ul>
                      <a href="javascript:void(0)" className="refresh">
                        <img src={RefreshIcon} alt="refresh icon" />
                      </a>
                    </div>
                    {/* <Button
                      variant="primary menu-btn dropdown-toggle"
                      onClick={handleShow}
                    >
                      <img src={UserIcon} alt="user" /> My Account
                    </Button> */}
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="primary menu-btn dropdown-toggle"
                        id="dropdown-basic"
                      >
                        <span className="my-account">
                          <img src={UserIcon} alt="user" /> My Account
                        </span>
                        <span className="setting">
                          <img src={SttingIcon} alt="stting icon" />
                        </span>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <h6 className="user">
                          {user?.mstruserid}
                          <span class="gmt" title="Time Zone">
                            GMT+5:30
                          </span>
                        </h6>
                        <Dropdown.Item href="/profile">
                          My Profile
                        </Dropdown.Item>
                        <Dropdown.Item href="/cashier">
                          Balance Overview
                        </Dropdown.Item>
                        <Dropdown.Item href="/cashier">
                          Account Statement
                        </Dropdown.Item>
                        <Dropdown.Item href="/mybets">My Bets</Dropdown.Item>
                        <Dropdown.Item href="/mybets">
                          Bets History
                        </Dropdown.Item>
                        <Dropdown.Item href="/cashier">
                          Profit & Loss
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="/"
                          className="logout"
                          onClick={() => dispatch(logoutUser())}
                        >
                          <span>
                            Logout <img src={LogoutIcon} alt="logout icon" />
                          </span>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="bot-header d-none d-xxl-block">
        <Container fluid>
          <Row>
            <Col xs={12} className="d-flex justify-content-between">
              <div className="middle-part">
                <Navbar expand="xl">
                  <Navbar.Toggle
                    aria-controls="basic-navbar-nav"
                    onClick={() => setShowToggle((s) => !s)}
                  />
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav>
                      <Nav.Link href="/home" className="logoImg d-xl-none">
                        <img src={appDetails?.LOGO_URL || logo} />
                      </Nav.Link>
                      <Nav.Link className="active" href="/home">
                        Home
                      </Nav.Link>
                      <Nav.Link href="javascript:void(0)">In-Play</Nav.Link>
                      <Nav.Link href="javascript:void(0)">
                        Multi Markets
                      </Nav.Link>
                      <Nav.Link href="/sportsbook">
                        Cricket
                        <div className="live-match">
                          <img src={LiveIcon} alt="live-icon" />
                          <span>2</span>
                        </div>
                      </Nav.Link>
                      <Nav.Link href="javascript:void(0)">
                        Soccer
                        <div className="live-match">
                          <img src={LiveIcon} alt="live-icon" />
                          <span>25</span>
                        </div>
                      </Nav.Link>
                      <Nav.Link href="javascript:void(0)">
                        Tennis
                        <div className="live-match">
                          <img src={LiveIcon} alt="live-icon" />
                          <span>9</span>
                        </div>
                      </Nav.Link>
                      <Nav.Link href="javascript:void(0)">
                        Virtual Cricket
                      </Nav.Link>
                      <Nav.Link href="javascript:void(0)">
                        E-Soccer
                        <div className="live-match">
                          <img src={LiveIcon} alt="live-icon" />
                          <span>2</span>
                        </div>
                      </Nav.Link>
                      <Nav.Link href="javascript:void(0)">IPL Winner</Nav.Link>
                      <Nav.Link href="javascript:void(0)">Binary</Nav.Link>
                      <Nav.Link href="javascript:void(0)">Sky Trader</Nav.Link>
                      <Nav.Link href="javascript:void(0)">Election</Nav.Link>
                      <Nav.Link className="new" href="/games-all">
                        {/* <div className="new-text">New</div> */}
                        <img src={NewImg} className="new-text" alt="casino" />
                        Casino <img src={CasinoIcon} alt="casino" />
                      </Nav.Link>
                      <Nav.Link href="javascript:void(0)">Result</Nav.Link>
                      {/* <Nav.Link href="/sportsbook">Sports</Nav.Link>
                      <Nav.Link href="/sports">Exchange</Nav.Link>
                      <Nav.Link href={CasinoProviders["ezugi"]?.href}>
                        Ezugi
                      </Nav.Link>
                      <Nav.Link href={CasinoProviders["evolution"]?.href}>
                        Evolution
                      </Nav.Link>
                      <Nav.Link href={CasinoProviders["worldcasino"]?.href}>
                        World Casino
                      </Nav.Link> */}
                      {/* <Nav.Link href="/games-all">
                          <GamesIcon /> Casino
                        </Nav.Link> */}
                      {/* <Nav.Link href="/cashier">Cashier</Nav.Link> */}
                    </Nav>
                  </Navbar.Collapse>
                </Navbar>
              </div>

              <div className="right-section d-flex">
                <Form>
                  {["checkbox"].map((type) => (
                    <div key={type} className="oneclick-bet">
                      <Form.Check type={type} id={`check-api-${type}`}>
                        <Form.Check.Input type={type} isValid />
                        <Form.Check.Label>{`One Click Bet`}</Form.Check.Label>
                      </Form.Check>
                    </div>
                  ))}
                </Form>
                <div className="settings">
                  <DropdownButton id="dropdown-basic-button" title="Setting">
                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">
                      Another action
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">
                      Something else
                    </Dropdown.Item>
                  </DropdownButton>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="news">
        <div className="news-text">News</div>
        <marquee scrollamount="2">
          <a>
            <span>02 May 2023</span>Event :- Gujarat Titans v Delhi Capitals ..
            Market .. 10 Over DC ' 52 - 54 ' (IST 20:01:50 - 20:01:55) Bets
            Voided Because Wrong Rate Offered by Mistake .. Sorry for the
            Inconvenience Caused
          </a>
          <a>
            <span>02 May 2023</span>Event :- Gujarat Titans v Delhi Capitals ..
            Market .. 10 Over DC ' 52 - 54 ' (IST 20:01:50 - 20:01:55) Bets
            Voided Because Wrong Rate Offered by Mistake .. Sorry for the
            Inconvenience Caused
          </a>
        </marquee>
      </div>

      <Offcanvas show={show} onHide={handleClose} placement={"end"}>
        <Offcanvas.Header closeButton="closeButton" closeVariant="white">
          <Offcanvas.Title>
            <div className="d-flex">
              <div className="profile-img">
                <ProfileIcon />
              </div>
              <div className="user-info">
                <span>{user?.mstruserid}</span>
                <p>{user?.mobileno}</p>
              </div>
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="row">
            <div className="col-6">
              <div className="balance mb-1">Main Balance</div>
            </div>
            <div className="col-6">
              <div className="balance-amount mb-1">
                {numberWithCommas(wallet?.cash || 0)}
              </div>
            </div>
            <div className="col-6">
              <div className="balance mb-3">Exchange Balance</div>
            </div>
            <div className="col-6">
              <div className="balance-amount mb-3">
                {numberWithCommas(wallet?.sports || 0)}
              </div>
            </div>
            <div
              className="col-4"
              onClick={() => {
                navigate("/withdraw");
              }}
            >
              <Button variant="primary  w-100">Withdraw</Button>
            </div>
            <div
              className="col-4"
              onClick={() => {
                navigate("/deposit");
              }}
            >
              <Button variant="primary w-100">Deposit</Button>
            </div>
            <div className="col-4">
              <Button variant="primary w-100" onClick={handleShowModal}>
                Exchange
              </Button>
              <ExchangeFundsFormPopUp
                showexchangeModal={showexchangeModal}
                handleCloseModal={handleCloseModal}
              />
            </div>
          </div>
          <ul className="menu-items">
            <li>
              <a href="/profile">
                <ProfileIcon />
                <span>Profile</span>
              </a>
            </li>
            <li>
              <a href="/mybets">
                <BetIcon />
                <span>My Bets</span>
              </a>
            </li>
            <li>
              <a href="/cashier">
                <StatementIcon />
                <span>Account Statement</span>
              </a>
            </li>
            {/* <li>
              <a href="#">
                <SettingIcon />
                <span>Transfer Funds ( For Excahnge)</span>
              </a>
            </li> */}
            <li>
              <a href="/settings">
                <SettingIcon />
                <span>Settings</span>
              </a>
            </li>
            <li onClick={() => dispatch(logoutUser())}>
              <a href="/">
                <LogoutIcon />
                <span>Sign Out</span>
              </a>
            </li>
          </ul>
          <ul className="social_links">
            <li>
              <a href="https://t.me/JuwaGhar">
                <TelegramIcon />
              </a>
            </li>
            {/* <li>
              <div
                onClick={() =>
                  window.open(appDetails?.WHATS_APP_URL, "_blank")
                }
              >
                <WhatsappIcon />
              </div>
            </li> */}
            <li>
              <div
                onClick={() =>
                  window.open("https://wa.me/447361634544", "_blank")
                }
              >
                <span className="notification-count">2</span>
                <WhatsappIcon />
              </div>
            </li>
          </ul>
        </Offcanvas.Body>
      </Offcanvas>

      <Modal
        className="betslip_popup"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showBetModal}
      >
        <Modal.Body>
          {" "}
          <main className="main">
            <div className="container">
              <table className="table text-white">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">exposureAmount</th>
                    <th scope="col">matchName</th>
                    <th scope="col">refMatchId</th>
                    <th scope="col">totalBets</th>
                  </tr>
                </thead>
                <tbody>
                  {betMatches &&
                    betMatches.length &&
                    betMatches.map((item, index) => {
                      return (
                        <tr
                          onClick={() => {
                            handleRowClick(item);
                          }}
                        >
                          <th scope="row">{index + 1}</th>
                          <td>{item?.exposureAmount}</td>
                          <td>{item?.matchName}</td>
                          <td>{item?.refMatchId}</td>
                          <td>{item?.totalBets}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </main>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowBetModal(false)}>Close</Button>
        </Modal.Footer>
      </Modal>

      <Modal
        className="betslip_popup"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showUserBetModal}
      >
        <Modal.Body>
          {" "}
          <main className="main">
            <div className="container">
              <table className="table text-white">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">odds</th>
                    <th scope="col">stack</th>
                    <th scope="col">selectionType</th>
                    <th scope="col">status</th>
                    <th scope="col">createdAt</th>
                  </tr>
                </thead>
                <tbody>
                  {userBets &&
                    userBets.length &&
                    userBets.map((item, index) => {
                      return (
                        <tr>
                          <th scope="row">{index + 1}</th>
                          <td>{item?.odds}</td>
                          <td>{item?.stack}</td>
                          <td>{item?.selectionType}</td>
                          <td>{item?.status}</td>
                          <td>{item?.createdAt}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </main>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowUserBetModal(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </header>
  );
};

export default HeaderAfterLogin;
