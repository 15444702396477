import React from "react";
import CashierIcon from "../assets/svg/CashierIcon";
import GamesIcon from "../assets/svg/GamesIcon";
import HomeIcon from "../assets/svg/HomeIcon";
// import RiExchangeFill from "../assets/svg/ProfileIcon";
import SportsIcon from "../assets/svg/SportsIcon";
import Exchange from "../assets/images/Exchange.png";
import Whatsapp from "../assets/images/Whatsapp.svg";
import Telegram from "../assets/images/Telegram.svg";
// import Facebook from "../assets/images/Facebook.svg";
import Instagram from "../assets/images/Instagram.svg";
import logo from "../assets/svg/logo_new.png";
import RNG from "../assets/images/providers/rng-certified.png";
import Gambling from "../assets/images/providers/gambling-commission.png";
import ResponsibleG from "../assets/images/providers/responsible-gaming.png";
import Gamble18 from "../assets/images/providers/gamble-resp-18.png";
import PaymentMod from "../assets/images/providers/payment-option.png";
import Betfair from "../assets/images/providers/betfair.png";
import SupportIcon from "../assets/images/support-icon.svg";
import WhatsappIcon from "../assets/images/whatsapp-icon.svg";
import SkypeIcon from "../assets/images/skype-icon.svg";
import TelegramIcon from "../assets/images/telegram-icon.svg";
import InstagramIcon from "../assets/images/instagram-icon.svg";
import BetfairImg from "../assets/images/betfair-img.png";
import BrowserIcons from "../assets/images/browser-icons.png";
import DownloadApp from "../assets/images/downloadApp-img.png";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const BeforeLoginFooter = () => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { appDetails } = useSelector((state) => state.app);

  return (
    <footer className="footer cust-container">
      <div class="support_sec">
        <div class="support-box">
          <div class="inner-box">
            <img src={SupportIcon} alt="support" title="customer" />
            <a href="javascript:void(0)" target="_blank">
              Customer support1
            </a>
            <a href="javascript:void(0)" target="_blank">
              support2
            </a>
          </div>
          <div class="inner-box">
            <img src={WhatsappIcon} alt="whatsapp" title="WhatsApp" />
            <a href="javascript:void(0)" target="_blank">
              what's app 3
            </a>
            <a href="javascript:void(0)" target="_blank">
              what's app 4
            </a>
          </div>
        </div>

        <div class="inner-box"></div>

        <div class="support-box">
          <div class="social-box">
            <img
              src={SkypeIcon}
              alt="skype"
              title="Skype"
              class="support-skype"
            />
            <a href="javascript:void(0)" target="_blank">
              <span className="d-none d-md-block">skyexchangeofficial</span>
              <span className="d-md-none">Skype</span>
            </a>
          </div>
          <div class="social-box">
            <img
              src={TelegramIcon}
              alt="telegram"
              title="Email"
              class="support-mail"
            />
            <a href="mailto:info@skyexchange.com" target="_blank">
              <span className="d-none d-md-block">info@skyexchange.com</span>
              <span className="d-md-none">Email</span>
            </a>
          </div>
          <div class="social-box">
            <img
              src={InstagramIcon}
              alt="instagram"
              title="Instagram"
              class="support-ig"
            />
            <a href="javascript:void(0)" target="_blank">
              <span className="d-none d-md-block">officialskyexchange</span>
              <span className="d-md-none">Instagram</span>
            </a>
          </div>
        </div>
      </div>

      <div class="power-wrap d-none d-md-flex">
        <h3 id="powerWrapW">
          <span>Powered by</span>
          <img src={BetfairImg} alt="betfair" />
        </h3>
        <div class="licence_embed">
          <iframe
            src="https://licensing.gaming-curacao.com/"
            width="150"
            height="50"
            style={{ border: "none" }}
          ></iframe>
        </div>
        <p>
          Skyexchange is operated by Sky Infotech N.V. a limited liability
          company incorporated under the laws of Curacao with company
          Registration number 152377 with registered office at Abraham de
          Veerstraat 9 , Curacao P.O Box 3421 and is licensed and regulated by
          the Curacao authority as the regulatory body responsible holding a
          (Sub-license with License number 365/JAZ Sub-License GLH-
          OCCHKTW0707072017 granted on 6.07.2017).
          <br />
          Players are requested not to contact any untrusted sources for
          skyexchange.com accounts as this is an online site and they can only
          register independently without any agents. Only deposit through the
          account details generated by the system or provided by our official
          support team.
        </p>
      </div>

      <div class="browser-wrap d-none d-md-block">
        <img src={BrowserIcons} alt="browser icons" />
        <br />
        Our website works best in the newest and last prior version of these
        browsers: <br />
        Google Chrome. Firefox
      </div>

      <ul class="policy-link">
        <li>
          <a href="javascript:void(0)">Privacy Policy</a>
        </li>
        <li>
          <a href="javascript:void(0)">Terms and Conditions</a>
        </li>
        <li>
          <a href="javascript:void(0)">Rules and Regulations</a>
        </li>
        <li>
          <a href="javascript:void(0)">KYC</a>
        </li>
        <li>
          <a href="javascript:void(0)">Responsible Gaming</a>
        </li>
        <li>
          <a href="javascript:void(0)">About Us</a>
        </li>
        <li>
          <a href="javascript:void(0)">Self-exclusion Policy</a>
        </li>
        <li>
          <a href="javascript:void(0)">Underage Policy</a>
        </li>
      </ul>

      <div class="app-link" style={{ display: "block" }}>
        <a href="javascript:void(0)">
          <img src={DownloadApp} alt="download app" />
        </a>
        <p>v1.11 - 2022-03-23 - 3.1MB</p>
      </div>

      <div className="container d-none">
        <div className="providers_img">
          <a href="javascript:void(0)" style={{ width: "65px" }}>
            <img src={RNG} loading="lazy" alt="RNG Certified" />
          </a>
          <a href="javascript:void(0)" style={{ width: "80px" }}>
            <img src={Gambling} loading="lazy" alt="Gambling Commission" />
          </a>
          <a href="javascript:void(0)" style={{ width: "130px" }}>
            <img src={ResponsibleG} loading="lazy" alt="Responsible Gaming" />
          </a>
          <a href="javascript:void(0)" style={{ width: "120px" }}>
            <img src={Gamble18} loading="lazy" alt="Gamble Responsible" />
          </a>
        </div>

        <div className="helpline">
          <a
            className="panel"
            href={
              appDetails?.WHATS_APP_URL != ""
                ? `https://wa.me/${appDetails?.WHATS_APP_URL}`
                : "javascript:void(0)"
            }
            target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
          >
            WhatsApp Us
          </a>
          {/* <a href="tel:{user?.mobileno}">{user?.mobileno}</a> */}
        </div>

        <div className="footer-links">
          <ul>
            <li>
              <a href="/contactuss">Contact Us</a>
            </li>
            <li>
              <a href="/privacypolicys ">Privacy Policy</a>
            </li>
            <li>
              <a href="/responsiblegames">Responsible Gaming</a>
            </li>
            <li>
              <a href="/fairplays">Fair Play</a>
            </li>
            <li>
              <a href="/gamesrules">Games Rules</a>
            </li>
            <li>
              <a href="/termss">Terms and Conditions</a>
            </li>
          </ul>
        </div>

        <div class="d-flex justify-content-center mt-2">
          <p style={{ color: "rgb(204, 204, 204)" }}>
            Accept, process &amp; disburse digital payments for your business.
          </p>
        </div>
        <div class="d-flex justify-content-center mt-2">
          <img
            src={PaymentMod}
            loading="lazy"
            style={{ width: "250px" }}
            alt="Payment Otions"
          />
        </div>

        <div class="d-flex justify-content-center mt-3 mb-3">
          <img
            src={Betfair}
            loading="lazy"
            class="mb-3 betfair"
            alt="Betfair Logo"
          />
        </div>

        <p>{appDetails?.FOOTER_TEXT || ""}</p>
        <div className="paymentImages d-none">
          <ul>
            <li>
              <img src="./images/sewa.jpeg" />
            </li>
            <li>
              <img src="./images/khalti.jpeg" />
            </li>
          </ul>
        </div>

        <div className="social-icons d-none">
          <ul>
            <li>
              <a
                className="panel"
                href={
                  appDetails?.WHATS_APP_URL != ""
                    ? appDetails?.WHATS_APP_URL
                    : "javascript:void(0)"
                }
                target={appDetails?.WHATS_APP_URL != "" ? "_blank" : ""}
              >
                {" "}
                <img src={Whatsapp} alt="Whatsapp Icon" />
                {/* <span className="notification-count">2</span> */}
              </a>
            </li>
            <li>
              <a target="_blank" href="https://t.me/daddy365">
                <img src={Telegram} alt="Telegram Icon" />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.facebook.com/profile.php?id=100091669203210"
              >
                <img src="/images/facebook-512.svg" alt="Facebook Icon" />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.instagram.com/officialdaddy365/"
              >
                <img src={Instagram} alt="Instagram Icon" />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-menu position-fixed start-0 bottom-0 z-index-2 w-100 d-block d-lg-none">
        <ul className="d-flex justify-content-evenly m-0 p-0 list-unstyled">
          <li>
            <a className="active" href="/">
              <HomeIcon />
              <span>Home</span>
            </a>
          </li>
          <li>
            <a
              href=""
              onClick={() => {
                navigate("sports/cricket");
              }}
            >
              <SportsIcon />
              <span>Exchange</span>
            </a>
          </li>
          {/* <li>
              <a
                href=""
                onClick={() => {
                  navigate("/sign-in");
                }}
              >
                <img src={Exchange} alt="Exchange Icon" />
                <span>Exchange</span>
              </a>
            </li> */}
          <li>
            <a
              href=""
              onClick={() => {
                navigate("/sign-in");
              }}
            >
              <GamesIcon />
              <span>Ezugi</span>
            </a>
          </li>
          <li>
            <a
              href=""
              onClick={() => {
                navigate("/sign-in");
              }}
            >
              <CashierIcon />
              <span>Deposit</span>
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default BeforeLoginFooter;
