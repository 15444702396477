import React, { useRef } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { IoWalletOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import numberWithCommas from "../../utils/numbersWithComma";

import { APP_CONST } from "../../config/const";
import { asyncSocketEmit, socket } from "../../utils/socketUtil";
import { Toaster } from "../../utils/toaster";
import { balanceUpdate } from "../../redux/auth/actions";
import { useState } from "react";
import { useEffect } from "react";

export default function ExchangeFundsFormPopUp({
  showexchangeModal,
  handleCloseModal,
}) {
  const dispatch = useDispatch();
  const { wallet } = useSelector((state) => state.auth);
  const [amount, setAmount] = useState(0);
  const [errors, setErrors] = useState("");

  useEffect(() => {
    return () => {};
  }, [amount, wallet]);

  const handleSubmit = async (type) => {
    // Validations
    if (type === "debit") {
      if (parseFloat(amount.toString()) <= 0) {
        setErrors("Please enter a valid amount");
        return;
      }
      if (
        parseFloat(amount.toString()) >
        parseFloat(wallet?.sports?.toString() || "0")
      ) {
        setErrors("Insufficient Exchange balance ");
        return;
      }
      setErrors("");
    }
    if (type === "credit") {
      if (parseFloat(amount.toString()) < 5) {
        setErrors("Amount must be greater than 5");
        return;
      }
      if (
        parseFloat(amount.toString()) > parseFloat(wallet?.cash?.toString())
      ) {
        setErrors("Insufficient wallet balance");
        return;
      }
      setErrors("");
    }

    const res = await asyncSocketEmit("post:betfair_transfer", {
      amount: amount,
      type: type,
    });

    if (res?.status === "failed") {
      Toaster(APP_CONST.TOAST_TYPE.ERROR, res.message);
      return;
    }

    if (res?.status === "success") {
      const newBal = await asyncSocketEmit("get:balance");

      // Manually get balance
      if (newBal?.status === "success") {
        dispatch(balanceUpdate(newBal));
      }

      if (res?.data?.errorCode) {
        Toaster(APP_CONST.TOAST_TYPE.ERROR, res.data?.message);
        return;
      }

      setAmount(0);

      if ("balance" in res?.data) {
        Toaster(APP_CONST.TOAST_TYPE.SUCCESS, "Transfered Successfully");
      }

      return;
    }
  };
  return (
    <Modal
      show={showexchangeModal}
      onHide={handleCloseModal}
      className="exchange-popup"
    >
      <Modal.Header closeButton>
        <Modal.Title>Exchange Top Up</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="balnace-wallet">
          <div className="balance-value">
            <div>
              <span></span>
              <span>
                <IoWalletOutline />
                Wallet Balance
              </span>
            </div>
            <div className="value">
              <span> {numberWithCommas(wallet?.cash || 0)}</span>
            </div>
          </div>
          <div className="balance-value">
            <div>
              <span></span>
              <span>
                <IoWalletOutline />
                Exchange Balace
              </span>
            </div>
            <div className="value">
              <span> {numberWithCommas(wallet?.sports || 0)}</span>
            </div>
          </div>
        </div>
        <div className="amount-input">
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>
                Amount <span className="ms-3 text-danger">{errors}</span>
              </Form.Label>
              <Form.Control
                type="number"
                name="amount"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </Form.Group>

            <div className="exchange-button row">
              <div className="col-6" onClick={() => handleSubmit("debit")}>
                <Button variant="primary  w-100">Withdraw</Button>
              </div>
              <div className="col-6" onClick={() => handleSubmit("credit")}>
                <Button variant="primary w-100">Deposit</Button>
              </div>
            </div>
          </Form>
        </div>
      </Modal.Body>
    </Modal>
  );
}
